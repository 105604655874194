<template>
  <div>
    <template v-if="menu.children && menu.children.length > 0">
      <a-sub-menu :key="menu.path">
        <template v-slot:title>
          <div :title="menu.name">
            <InlineSvg
                v-if="menu.icon.startsWith('ems')"
                :src="`/assets/ems/${menu.icon}.svg`"
                :alt="menu.icon"
                height="18"
                width="18"
                class="fill-current text-ems-gray500 ems-icon flex-shrink-0"
            />
            <cds-feather-icons
                v-else
                :type="menu.icon"
                size="16"
                class="text-ems-gray500 flex-shrink-0"
            />
            <span>{{ menu.name }}</span>
          </div>
        </template>
        <tree-menu
          v-for="(child, i) in menu.children"
          :key="child.path + i"
          :menu="child"
        ></tree-menu>
      </a-sub-menu>
    </template>

    <template v-else>
      <a-menu-item @click="$emit('toggleCollapsed')" :key="menu.path" :title="menu.name">
        <router-link :to="!menu.id ? '/' : menu.path">
          <InlineSvg
            v-if="menu.icon.startsWith('ems')"
            :src="`/assets/ems/${menu.icon}.svg`"
            :alt="menu.icon"
            height="18"
            width="18"
            class="fill-current text-ems-gray500 ems-icon flex-shrink-0"
          />
          <cds-feather-icons
            v-else
            :type="menu.icon"
            size="16"
            class="text-ems-gray500 flex-shrink-0"
          />
          <span :title="menu.name">{{ menu.name }}</span>
        </router-link>
      </a-menu-item>
    </template>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import VueTypes from 'vue-types';
import InlineSvg from 'vue-inline-svg';

export default defineComponent({
  name: 'TreeMenu',
  components: { InlineSvg },
  props: {
    menu: VueTypes.object,
  },
  emits: ['toggleCollapsed'],
});
</script>

<style scoped>

</style>
