<template>
  <div class="relative settings">
    <div
      v-if="checkIsSeenAllNoti"
      class="absolute right-[5px] w-[14px] h-[14px] text-[9px] bg-white rounded-full text-kns-main flex items-center justify-center"
    >
      <span>{{ notifications.totalElements }}</span>
    </div>
    <cds-popover
      placement="bottomRight"
      action="click"
      overlay-class-name="popover-header"
    >
      <template v-slot:content>
        <div class="rounded-md">
          <h3
            class="text-white px-3 py-2 text-base font-bold border-b text-center"
          >
            {{ t('common.notification') }}
          </h3>
          <div
            ref="scrollComponent"
            v-if="notifications.length > 0"
            class="max-h-[400px] overflow-auto w-[400px] max-w-full"
          >
            <NotificationItem
              class="p-3"
              v-for="notification in notifications"
              :key="notification.id"
              :notification="notification"
            />
          </div>
          <div
            class="max-h-[400px] overflow-auto w-[400px] max-w-full py-4"
            v-else
          >
            <Empty />
          </div>
        </div>
      </template>
      <a to="#" class="head-example px-0 !mx-[15px]">
        <cds-feather-icons type="bell" size="20" class="text-kns-gold" />
      </a>
    </cds-popover>
  </div>
</template>

<script>
import { defineComponent, computed, ref, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import NotificationItem from './NotificationItem.vue';
import Empty from '@/components/empty/index.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'Notifications',
  components: {
    NotificationItem,
  },
  setup() {
    const { dispatch, state } = useStore();
    const { t } = useI18n();
    const scrollComponent = ref(null);
    const notifications = computed(() => state.auth.notifications);
    const isContinue = computed(() => state.auth.isContinue);
    const seen = (element) => element.seen === false;
    const checkIsSeenAllNoti = computed(() => {
      if (state.auth.notifications) {
        if (state.auth.notifications.content) {
          return state.auth.notifications.content.some(
            (element) => element.seen === false
          );
        } else return false;
      } else return false;
    });

    onMounted(() => {
      window.addEventListener('scroll', handleScroll);
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll);
    });

    const handleScroll = () => {
      if (scrollComponent.value) {
        const element = scrollComponent.value;
        const rect = element.getBoundingClientRect();
        const isAtBottom = rect.bottom <= window.innerHeight;

        if (isAtBottom && isContinue.value) {
          dispatch('getNotifications');
        }
      }
    };
    return {
      scrollComponent,
      notifications,
      checkIsSeenAllNoti,
      t,
    };
  },
});
</script>
