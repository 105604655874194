<template>
  <a
    :href="notification.url"
    @click.native="handleClick()"
    :class="notification.seen ? 'bg-white' : 'bg-gray-200'"
    class="flex flex-col px-4 py-3 border-b border-gray-300 hover:bg-gray-200"
  >
    <p class="mb-1 text-sm font-bold">{{ notification.title }}</p>
    <div
      v-if="
        notification.additional.status ===
        STATUS_NOTIFY_REQUEST.REQUEST_PROCESSING
      "
      class="flex items-center"
    >
      <p>
        Từ <b>{{ notification.additional.senderOrgName }}</b> -
        {{ formatDateTime }}
      </p>
    </div>
    <div v-else class="flex items-center">
      <p>
        <b>{{ notification.additional.receiverOrgName }}</b> -
        <b>{{ notification.additional.statusText }}</b> - {{ formatDateTime }}
      </p>
    </div>
  </a>
</template>
<script>
import { computed, defineComponent } from 'vue';
import { formatDate } from '@/util/common-utils';
import ConstantAPI from '@/config/ConstantAPI';
import { STATUS_NOTIFY_REQUEST } from '@/config/Constant';
import { DataService } from '@/dataService/dataService';
export default defineComponent({
  name: 'NotificationItem',
  props: {
    notification: Object,
  },
  setup(props) {
    const formatDateTime = computed(() =>
      formatDate(props.notification.createdTime, 'dd/MM/yyyy HH:mm:ss')
    );
    const handleClick = async () => {
      try {
        const result = await DataService.patch(
          `${ConstantAPI.user.MARK_READ_NOTIFICATION.url}/${props.notification.id}`
        );
      } catch (error) {
        console.log(error);
      }
    };
    return {
      formatDateTime,
      handleClick,
      STATUS_NOTIFY_REQUEST,
    };
  },
});
</script>
