<template>
  <div
    class="absolute w-[666px] h-[70px] top-0 z-[1000]"
    style="left: 50%; transform: translateX(-50%)"
  >
    <div class="relative w-full h-full">
      <div class="absolute">
        <svg
          width="664"
          height="69"
          viewBox="0 0 664 69"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M664 0H350.003H342.398H319.593H311.988H-2L79.7455 58.1084C89.7065 65.1849 101.681 69 113.972 69H312.005H319.61H342.416H350.021H548.055C560.346 69 572.32 65.1849 582.281 58.1084L664 0Z"
            fill="url(#paint0_linear_2001_280)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_2001_280"
              x1="325.939"
              y1="60.9892"
              x2="326.688"
              y2="-40.2288"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#383737" />
              <stop offset="0.525006" stop-color="#252525" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div
        v-if="titleName !== ''"
        class="absolute text-main-1 uppercase w-full text-center flex justify-center alight-center h-full items-center"
      >
        <img
          class="h-full"
          src="@/static/img/arrow-right-double.svg"
          alt="arrow-right"
          width="21"
          height="14"
        />
        <span
          class="inline-block ml-3 text-ems-gray300 uppercase font-bold"
          :class="locale === 'ru' ? 'text-[16px]' : 'text-[20px]'"
          >{{ titleName }}</span
        >
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import VueTypes from 'vue-types';
import {useI18n} from "vue-i18n";
export default defineComponent({
  name: 'HeaderCustom',
  props: {
    titleName: VueTypes.string.def(''),
  },
  setup() {
    const { locale } = useI18n()
    return {
      locale
    }
  }
});
</script>
