<template>
  <InfoWraper>
    <Notifications />
    <!-- <Settings /> -->

    <div class="nav-author relative">
      <cds-popover
        placement="bottomRight"
        action="click"
        v-model:visible="isPopoverVisible"
        overlay-class-name="popover-header"
      >
        <template v-slot:content>
          <UserDropDwon>
            <div class="user-dropdwon" v-if="userInfo">
              <figure class="user-dropdwon__info !bg-ems-gray800">
                <a-avatar
                  v-if="userInfo"
                  class="mr-20 flex-shrink-0"
                  shape="square"
                  :size="70"
                  :src="userInfo.avatar"
                >
                </a-avatar>
                <figcaption>
                  <div class="max-w-[120px] h-max">
                    <cds-heading as="h5" class="text-white break-words">{{
                      userInfo.fullName
                    }}</cds-heading>
                  </div>
                  <p class="text-white truncate max-w-150px" v-if="userInfo">
                    {{ userInfo.username }}
                  </p>
                  <p class="text-center text-white" v-if="userInfo.investor">
                    {{ userInfo.investor.name }}
                  </p>
                </figcaption>
              </figure>
              <ul class="user-dropdwon__links">
                <li>
                  <a
                    @click="router('profile', $t('common.user_info'))"
                    class="text-white"
                  >
                    <div class="flex items-center">
                      <img
                        src="@/static/img/icon/info-icon.svg"
                        class="h-20px"
                        alt=""
                      />
                      <span class="font-semibold">{{
                        $t('common.user_info')
                      }}</span>
                    </div>
                  </a>
                </li>
                <!--                <li>-->
                <!--                  <a @click="router('account', $t('common.setting_account'))" class="text-white">-->
                <!--                    <cds-feather-icons type="settings" />-->
                <!--                    {{ $t('common.setting_account') }}</a-->
                <!--                  >-->
                <!--                </li>-->
                <li>
                  <a
                    @click="router('password', $t('common.change_password'))"
                    class="text-white"
                  >
                    <div class="flex items-center">
                      <img
                        src="@/static/img/icon/lock-icon.svg"
                        class="h-20px"
                        alt=""
                      />
                      <span class="font-semibold">{{
                        $t('common.change_password')
                      }}</span>
                    </div>
                  </a>
                </li>
              </ul>
              <a
                @click="SignOut"
                class="user-dropdwon__bottomAction !bg-ems-gray800 text-white"
                href="#"
              >
                <LogoutOutlined /> {{ $t('common.logout') }}
              </a>
            </div>
          </UserDropDwon>
        </template>
        <a to="#" class="head-example px-0 !ml-[18px] !flex">
          <div class="hexagone relative">
            <a-avatar
              v-if="userInfo"
              shape="square"
              :src="userInfo.avatar"
              class="avatar"
            >
            </a-avatar>
          </div>
        </a>
      </cds-popover>
    </div>
  </InfoWraper>
</template>

<script>
import { InfoWraper, UserDropDwon } from './auth-info-style';
// import Settings from './Settings';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { LogoutOutlined } from '@ant-design/icons-vue';
import { computed, defineComponent, onMounted, watchEffect, ref } from 'vue';
import Notifications from './Notifications';
export default defineComponent({
  name: 'AuthInfo',
  components: {
    InfoWraper,
    UserDropDwon,
    // Settings,
    LogoutOutlined,
    Notifications,
  },
  data() {
    return {
      flag: 'english',
    };
  },
  setup() {
    const { dispatch, state } = useStore();
    const isPopoverVisible = ref(false);
    const { push } = useRouter();
    const isLoading = computed(() => state.firebase.loading);
    const SignOut = (e) => {
      e.preventDefault();
      push('/auth/login');
      dispatch('logOut');
    };

    const router = (page, title) => {
      dispatch('setTitleHeader', title).then(() =>
        push(`/settings/profile-settings/${page}`)
      );
    };
    const userInfo = computed(() => state.auth.userInfo);
    onMounted(() => {
      window.addEventListener('resize', handleResize);
    });
    const handleResize = () => {
      isPopoverVisible.value = false;
    };
    watchEffect(() => {
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    });
    return {
      SignOut,
      router,
      isLoading,
      userInfo,
      isPopoverVisible,
    };
  },
  methods: {
    onFlagChangeHandle: function (value) {
      this.flag = value;
    },
  },
});
</script>
<style lang="scss">

.hexagone {
  display: inline-block;
  width: 48px;
  height: 44px;
  background-color: red;
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  .avatar {
    width: 43px;
    height: 40px;
    line-height: 50px;
    font-size: 18px;
    position: absolute;
    top: 2px;
    left: -1px;
    clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  }
}

.ant-popover-inner {
  background: #262626;
}

</style>
